// types
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { FeeCategory, FeesCategoriesType } from '@makemydeal/dr-shared-types';
import { CustomFeeItem, DealerFeeWithIncludeCal, Detail, GovernmentFeeWithIncludeCal, LeaseDetail } from './types';

export const isLastRow = <T>({ index, rows }: { index: number; rows: T[] }) => {
    return index === rows.length - 1;
};

export const isLeaseFinanceDetail = (isLease: boolean, feeDetailRow: Detail): feeDetailRow is LeaseDetail => isLease;

export const getFeeDetailsRows = ({ feeItems, isLease }: { isLease: boolean; feeItems: CustomFeeItem[] }) => {
    return feeItems.map((feeItem) => {
        const feeDetailRow: Detail = {
            name: feeItem.feeName,
            code: feeItem.feeTypeId,
            amount: `${feeItem.feeAmount}`,
            category: feeItem.category,
            isManual: feeItem.isManualFee,
            isEdited: feeItem.isEdited,
            isDeleted: feeItem.isDeleted,
            includedInCalc: !!feeItem.includedInCalc
        };

        if (isLeaseFinanceDetail(isLease, feeDetailRow)) {
            const isFeeUpfront = !feeItem.capIndicator;
            feeDetailRow.upfront = isFeeUpfront;
        }

        return feeDetailRow;
    });
};

export const hasChangeGovFees = ({
    initialGovFees,
    updatedGovFees
}: {
    initialGovFees: CustomFeeItem[];
    updatedGovFees: CustomFeeItem[];
}) => {
    return JSON.stringify(initialGovFees) !== JSON.stringify(updatedGovFees);
};

export const hasChangeDealerFees = ({
    initialDealerFees,
    updatedDealerFees
}: {
    initialDealerFees: CustomFeeItem[];
    updatedDealerFees: CustomFeeItem[];
}) => {
    return JSON.stringify(initialDealerFees) !== JSON.stringify(updatedDealerFees);
};

export const hasChangeLenderFees = ({
    initialLenderFees,
    updatedLenderFees
}: {
    initialLenderFees: CustomFeeItem[];
    updatedLenderFees: CustomFeeItem[];
}) => {
    return JSON.stringify(initialLenderFees) !== JSON.stringify(updatedLenderFees);
};

export const getAreFeesValid = (dealerFees: CustomFeeItem[], govFees: CustomFeeItem[]): boolean => {
    const areDealerFeesValid = dealerFees.every((fee: CustomFeeItem): boolean => {
        return Boolean(fee.feeTypeId && parseInt(fee.feeAmount) >= 0);
    });

    const areGovernmentFeesValid = govFees.every((fee: CustomFeeItem): boolean => {
        return Boolean(fee.feeTypeId && parseInt(fee.feeAmount) >= 0);
    });

    return areDealerFeesValid && areGovernmentFeesValid;
};

const isGovernmentFees = (
    fee: DealerFeeWithIncludeCal | GovernmentFeeWithIncludeCal,
    category: paymentServicesTypes.FeeCategory
): fee is GovernmentFeeWithIncludeCal => {
    return category === FeesCategoriesType.GOVERNMENT;
};

export const getCustomFeeItemsAdapter = (
    feeItem: (DealerFeeWithIncludeCal | GovernmentFeeWithIncludeCal)[],
    categoryType: paymentServicesTypes.FeeCategory
): CustomFeeItem[] => {
    return feeItem.map((fee) => {
        if (isGovernmentFees(fee, categoryType)) {
            return {
                feeTypeId: fee.code,
                feeAmount: `${fee.amount}`,
                capIndicator: fee.capped,
                feeDescription: fee.description,
                feeName: fee.name || fee.description,
                category: categoryType,
                isManualFee: fee.isManualFee,
                includedInCalc: fee.includedInCalc ?? true,
                isDeleted: fee.isManualFee && fee.amount === 0,
                isEdited: fee.isManualFee && fee.amount > 0
            };
        }

        return {
            feeTypeId: fee.dealerFeeTypeCode,
            feeAmount: `${fee.dealerFeeAmount}`,
            capIndicator: fee.dealerFeeCapped,
            feeDescription: fee.dealerFeeDesc,
            feeName: fee.dealerFeeName || fee.dealerFeeDesc,
            category: categoryType,
            includedInCalc: fee.includedInCalc ?? true,
            isManualFee: fee.isManualFee,
            isDeleted: fee.isManualFee && fee.dealerFeeAmount === 0,
            isEdited: fee.isManualFee && fee.dealerFeeAmount > 0
        };
    });
};

type IdentifiableFee = {
    dealerFeeTypeCode?: number;
    code?: number;
    id?: number;
    feeTypeId?: number;
    feeAmount?: string;
    dealerFeeAmount?: number;
};

export const getFeeCodes = (feeItems: IdentifiableFee[]): number[] => {
    if (!feeItems?.length) return [];

    return feeItems
        .filter((fee) => {
            return fee.dealerFeeAmount !== 0;
        })
        .map((fee) => fee.dealerFeeTypeCode || fee.code || fee.id || fee.feeTypeId || -1);
};
//TODO combine getFeeCodes and getFeeCodesForItemizedFees into one function
export const getFeeCodesForItemizedFees = (feeItems: IdentifiableFee[]): number[] => {
    if (!feeItems?.length) return [];

    return feeItems
        .filter((fee) => {
            return Number(fee.feeAmount) !== 0;
        })
        .map((fee) => fee.dealerFeeTypeCode || fee.code || fee.id || fee.feeTypeId || -1);
};

export const filterFeesById = (fees: FeeCategory[], exclusionList: number[], include: boolean = false) => {
    return fees.filter((fee) => {
        const isInExclusionList = exclusionList.includes(fee.id);
        return include ? isInExclusionList : !isInExclusionList;
    });
};

export const mergeFeeUpdates = (fees: CustomFeeItem[]): CustomFeeItem[] => {
    return fees.reduce((acc, current) => {
        const existingIndex = acc.findIndex((item) => item.feeTypeId === current.feeTypeId && item.isDeleted);
        if (existingIndex !== -1) {
            acc[existingIndex] = current; // Update existing deleted fee
        } else {
            acc.push(current); // Add new fee
        }
        return acc;
    }, [] as CustomFeeItem[]);
};
