// libraries
import type { PaymentProduct, StateTree } from '@makemydeal/dr-dash-types';

// consts/enums
import { ACQUISITION_FEE_ID, DEALER_ROLE, SHOPPER_ROLE } from '../constants';

// selectors
import { isDealerUser, getUserRole } from './widgetSettingsSelectors';
import { getOfferPrice, getNetCashDown, getCapReductionRebate, getCapReductionTradeIn, getCapCostReductionTax } from './offerRedux';
import { getDealerFeeTotal, getDmvFeeTotal, getMonthlyPayment, getCappedDealerFeesTotal } from './offerSelectors';
import {
    getAccessoriesPaymentProducts,
    getAccessoriesTotalAmount,
    getCapAccessoriesTotal
} from '../selectors/accessoriesSelectors';
import * as offerReduxSelectors from './offerRedux';
import * as vehicleProtectionSelectors from './vehicleProtectionSelectors';
import { getVppPaymentProducts } from './vehicleProtectionSelectors';

export const getPaymentProducts = (state: StateTree): PaymentProduct[] => {
    const accessories = getAccessoriesPaymentProducts(state);
    const products = getVppPaymentProducts(state);

    return [...accessories, ...products];
};

export const isDeskingUser = (state: StateTree): boolean => {
    const isDealer = isDealerUser(state);
    const isDeskingManager = getUserRole(state) === 'desking_manager';
    return isDealer && isDeskingManager;
};

export const getPersona = (state: StateTree): string => {
    return isDealerUser(state) ? DEALER_ROLE : SHOPPER_ROLE;
};

/**
 * Returns the total addon for finance and cash.
 * Total Add-ons = Accessories, Protection Products, Fees (Dealer & Govt)
 */
export const getTotalAddons = (state: StateTree): number => {
    const protectionTotal = vehicleProtectionSelectors.getVppSummary(state).totalProductPrice || 0;
    // TODO: When accessories is implemented, make sure the total includes only capitalized accessories for lease
    const accessoriesTotal = getAccessoriesTotalAmount(state);
    const govFeesTotal = getDmvFeeTotal(state);
    const dealerFeeTotal = getDealerFeeTotal(state);

    return govFeesTotal + accessoriesTotal + protectionTotal + dealerFeeTotal;
};

export type DealerFee = {
    dealerFeeTypeCode: number;
    dealerFeeAmount: number;
    dealerFeeName: string;
    dealerFeeDesc?: string;
    dealerFeeCapped?: boolean;
    originalCategory?: string;
};

export const getDealerFees = (state: StateTree, capped: boolean): DealerFee[] => {
    const dealerFees = offerReduxSelectors.getDealerFees(state) as DealerFee[];
    const filteredFees = dealerFees.filter(
        (fee) =>
            fee.originalCategory?.toLowerCase() === 'dealer' && // must be dealer (not lender)
            fee.dealerFeeCapped === capped && // capped or not
            fee.dealerFeeTypeCode !== ACQUISITION_FEE_ID
    ); // exclude lender fee: acq
    return filteredFees;
};

export const getDealerFeesTotal = (state: StateTree, capped: boolean): number => {
    const fees = getDealerFees(state, capped);
    const result = fees.reduce((accumulator, cur) => accumulator + cur.dealerFeeAmount, 0);
    return result;
};

export const getDealerFeesUpfrontTotal = (state: StateTree): number => {
    const fees = getDealerFees(state, false);
    const result = fees.reduce((accumulator, cur) => accumulator + cur.dealerFeeAmount, 0);
    return result;
};

export const getDealerFeesUpfront = (state: StateTree) => {
    const fees = getDealerFees(state, false);
    return fees;
};

/**
 * Get goverment fees for current offer type and term.
 * @param state
 * @param capped Capitalized or not
 */
export const getGovernmentFees = (state: StateTree, capped: boolean) => {
    const govFees = offerReduxSelectors.getGovernmentFees(state);
    return govFees.fee.taxFee.filter((t) => t.capped === capped);
};
export const getGovernmentFeesUpFront = (state: StateTree) => {
    return getGovernmentFees(state, false);
};

export const getLenderFees = (state: StateTree, capped: boolean) => {
    const lenderFees = offerReduxSelectors.getItemizedLenderFees(state);
    return lenderFees.filter((t) => t.dealerFeeCapped === capped);
};

export const getLenderFeesTotal = (state: StateTree) => {
    const lenderFees = offerReduxSelectors.getItemizedLenderFees(state);
    return lenderFees.reduce((accumulator, cur) => accumulator + cur.dealerFeeAmount, 0);
};

export const getLenderFeesUpFront = (state: StateTree) => {
    return getLenderFees(state, false);
};

export const getLenderFeesUpFrontTotal = (state: StateTree) => {
    const total = getLenderFeesUpFront(state).reduce((acc, cur) => acc + cur.dealerFeeAmount, 0);
    return total;
};
/**
 * Returns the Net Selling Price for Finance/Cash
 * Net Selling Price = Selling Price + Total Add-ons + Taxes

 */
export const getNetSellingPrice = (state: StateTree): number => {
    const offerPrice = getOfferPrice(state);
    const totalAddons = getTotalAddons(state);

    return offerPrice + totalAddons;
};

export const getCapCostReduction = (state: StateTree) => {
    const netCashDown = getNetCashDown(state);
    const reductionRebate = getCapReductionRebate(state);
    const reductionTradeIn = getCapReductionTradeIn(state);
    return netCashDown + reductionRebate + reductionTradeIn;
};

/*
 The sum of:
 Protection Products, Capped Accessories, Capped Fees (gov & dealer), First Month's payment
 */
export const getAddsToCapCostTotal = (state: StateTree) => {
    const protectionTotal = vehicleProtectionSelectors.getCapVppTotal(state);
    const capDealerFeeTotal = getCappedDealerFeesTotal(state);
    const accessoriesTotal = getCapAccessoriesTotal(state);
    const governmentFeeTotal = offerReduxSelectors.getGovernmentFees(state).cappedFeeTotal;

    return accessoriesTotal + capDealerFeeTotal + governmentFeeTotal + protectionTotal;
};

export function getTotalUpfronts(state: StateTree): number {
    const ccrTax = getCapCostReductionTax(state);
    const firstPayment = getMonthlyPayment(state);

    return ccrTax + firstPayment;
}

export function getCapitalizedTax(state: StateTree): number {
    const isTaxCapped = offerReduxSelectors.isTaxCapped(state);
    const advanceFlatTax = offerReduxSelectors.getAdvanceFlatTax(state);
    const advanceSalesTax = offerReduxSelectors.getAdvanceSalesTax(state);
    const hasManualTotalTax = offerReduxSelectors.hasManualTotalTax(state);

    return !isTaxCapped ? 0 : hasManualTotalTax ? advanceFlatTax : advanceSalesTax;
}

export function getUpfrontAdvanceFlatTax(state: StateTree): number {
    const hasManualTotalTax = offerReduxSelectors.hasManualTotalTax(state);
    const upfrontTaxTotal = offerReduxSelectors.getUpfrontTaxTotal(state);

    return hasManualTotalTax ? 0 : upfrontTaxTotal;
}
