import { useState, FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ComboBox, ComboBoxOption } from '@interstate/components/ComboBox';
import { kebabCase } from '@makemydeal/dr-common-utils';
import { CUSTOM_MILEAGE_ERROR, CUSTOM_MILEAGE_LABEL, MILES_LABEL } from '../../constants';
import { offerReduxSelectors, offerReduxActionCreators } from '@makemydeal/dr-dash-store';

type AnnualMilesOptions = {
    id: number;
    name: string;
};

const milesMatcher = new RegExp(/^\s*(\d+)(\D+\w*|)$/);

export const createComboOption = (item: AnnualMilesOptions): ComboBoxOption => {
    const { id, name } = item;
    return {
        id: id.toString(),
        value: id.toString(),
        label: name.toString()
    };
};

export const getMatchedMileage = (selectedMileage: string): string => {
    const matches = selectedMileage?.match(milesMatcher);
    return matches?.length ? matches[1] : '';
};

export const inMilesRange = (selectedMileage: string): boolean => {
    const milesLength = selectedMileage?.length || 0;
    return Boolean(milesLength && milesLength < 6);
};

const CustomMileageField: FC = () => {
    const dispatch = useDispatch();

    const annualMilesOptions = useSelector(offerReduxSelectors.getAnnualMilesOptions);
    const selectedAnnualMiles = useSelector(offerReduxSelectors.getAnnualMiles).toString();

    const [resetMiles, setResetMiles] = useState(false);
    const [displayMiles, setDisplayMiles] = useState(selectedAnnualMiles);

    let onBlurMiles = displayMiles;

    useEffect(() => {
        if (resetMiles) {
            // requires two steps to reset the value
            setDisplayMiles('');
            setResetMiles(false);
        }
    }, [resetMiles]);

    useEffect(() => {
        setDisplayMiles(displayMiles || selectedAnnualMiles);
    }, [displayMiles]);

    const leaseMileageOptions = annualMilesOptions.map((item: AnnualMilesOptions) => createComboOption(item));

    const handleValueBlur = (event: any) => {
        // only sees the originally selected value
        const [selectedMileage] = event.target.value;

        // just in case but unreachable
        /* istanbul ignore next */
        if (selectedMileage == null) {
            return setResetMiles(true);
        }

        const milesValue = getMatchedMileage(selectedMileage.value);

        if (milesValue == onBlurMiles) {
            return setResetMiles(true);
        }
    };

    const handleValueChange = (event: any) => {
        const [selectedMileage] = event.target.value;

        if (selectedMileage == null) {
            return;
        }

        const milesValue = getMatchedMileage(selectedMileage.value);
        const isValidFormat = inMilesRange(milesValue);

        setDisplayMiles(milesValue);

        if (isValidFormat) {
            if (milesValue == selectedAnnualMiles) {
                return setResetMiles(true);
            } else {
                return dispatch(offerReduxActionCreators.selectedAnnualMiles(milesValue));
            }
        } else {
            onBlurMiles = milesValue;
        }
    };

    return (
        <>
            <ComboBox
                id={kebabCase(CUSTOM_MILEAGE_LABEL)}
                label={CUSTOM_MILEAGE_LABEL}
                options={leaseMileageOptions}
                onBlur={handleValueBlur}
                onChange={handleValueChange}
                allowNewEntry={true}
                hasError={!inMilesRange(displayMiles)}
                errorMessage={CUSTOM_MILEAGE_ERROR}
                displayNoDataMessage={false}
                data-testid={kebabCase(CUSTOM_MILEAGE_LABEL)}
                value={[{ value: displayMiles, label: `${displayMiles} ${MILES_LABEL}` }]}
            />
        </>
    );
};

export default CustomMileageField;
