// externals
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

// libraries
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';
import { offerReduxSelectors, offerSelectors, paymentInputActionCreators } from '@makemydeal/dr-dash-store';
import { FINANCE } from '@makemydeal/dr-platform-types';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
// utils
import { usePaymentCall } from '../../utils/usePaymentCall';

// styles
import { NumericInput } from '@interstate/components/NumericInput';

// consts/enums
import { deskingSelectors, offerActionTypes } from '@makemydeal/dr-dash-store';
import { CASH_DOWN, DUE_AT_SIGNING, GENERIC_LAST_FIELD_CHANGED_ERROR } from '../../constants';

export const CashDownField = () => {
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const {
        value: downPayment,
        handleChange,
        handleBlur
    } = usePaymentCall(offerSelectors.getDownPayment, paymentInputActionCreators.updateDownPayment, {
        formatValueToString: formatDollarsAndCents
    });

    const failed = useSelector(deskingSelectors.wasTheLastFailure(offerActionTypes.UPDATED_DOWN_PAYMENT_IMMEDIATE));
    const failedMessage = useMemo(() => (failed ? GENERIC_LAST_FIELD_CHANGED_ERROR : undefined), [failed]);

    const deductDueAtSigning = useSelector(dealerSelectors.getIsDueAtSigninAmountDeduct);
    const downPaymentLabelFinance = CASH_DOWN;
    const downPaymentLabelLease = deductDueAtSigning ? DUE_AT_SIGNING : CASH_DOWN;
    const label = offerType === FINANCE ? downPaymentLabelFinance : downPaymentLabelLease;

    const [inputError, setInputError] = useState('');

    const handleChangeWrapper = (e: InterstateOnChangeEvent<string>) => {
        if (e.target.value === '') {
            setInputError('Please enter a valid amount');
            return;
        }
        setInputError('');
        handleChange(e);
    };

    const errorState = inputError || failedMessage;
    const showError = Boolean(inputError || failed);

    return (
        <NumericInput
            placeholder="e.g. 2000.00"
            name={kebabCase(CASH_DOWN)}
            value={inputError ? '' : downPayment}
            onChange={handleChangeWrapper}
            onBlur={handleBlur}
            hasError={showError}
            errorMessage={errorState}
            label={label}
            data-testid={kebabCase(CASH_DOWN)}
            allowDecimal={true}
            decimalMaxLength={2}
            decimalMinLength={2}
            inputPrefix={'$'}
            autoInsertCommas={true}
        />
    );
};

export default CashDownField;
