// externals
import { useSelector } from 'react-redux';
import { useCallback, useMemo, useState, type FC } from 'react';

// libraries
import { Box } from '@interstate/components/Box';
import { Button } from '@interstate/components/Button';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// components
import { FeeResetDialog } from './FeeResetDialog';
import FeesFormCategoryItems from './FeesFormCategoryItems';
import { FeesFormContext, FeesFormContextProps } from './FeesFormContext';

// styles
import { FlexFeesButtonContainer } from './FeesForm.style';

// types/consts/enums
import { CASH, LEASE, paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { FeesCategoriesType } from '@makemydeal/dr-shared-types';
import { ELIMINATED_FEE_AMOUNT } from './constants';
import { CustomFeeItem } from './types';

// utils
import { createNewFee } from '../../utils/components';
import { getAreFeesValid, hasChangeDealerFees, hasChangeGovFees, hasChangeLenderFees, mergeFeeUpdates } from './utils';

type FeesFormProps = {
    toggleShowEditAction: () => void;
    offerType: string;
    governmentFeesItems: CustomFeeItem[];
    dealerFeesItems: CustomFeeItem[];
    lenderFeesItems: CustomFeeItem[];
    onReset?: () => void;
    onSave?: (updatedFees: paymentServicesTypes.FeeOverride[]) => void;
};

export const FeesForm: FC<FeesFormProps> = ({
    offerType,
    toggleShowEditAction,
    governmentFeesItems,
    dealerFeesItems,
    lenderFeesItems,
    onReset,
    onSave
}) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [govFees, setGovFees] = useState(governmentFeesItems);
    const [dealerFees, setDealerFees] = useState(dealerFeesItems);
    const [lenderFees, setLenderFees] = useState(lenderFeesItems);
    const [disableFeeSubmit, setDisableFeeSubmit] = useState(false);

    const enableLenderFeeEdits = useSelector(featureToggleSelectors.enableLenderFeeEdits);

    const hasChangedFees = useMemo(
        () =>
            hasChangeGovFees({
                initialGovFees: governmentFeesItems,
                updatedGovFees: govFees
            }) ||
            hasChangeDealerFees({ initialDealerFees: dealerFeesItems, updatedDealerFees: dealerFees }) ||
            hasChangeLenderFees({ initialLenderFees: lenderFeesItems, updatedLenderFees: lenderFees }),
        [governmentFeesItems, govFees, dealerFeesItems, dealerFees, lenderFeesItems, lenderFees]
    );
    const areFeesValid = getAreFeesValid(dealerFees, govFees);
    const isLease = offerType === LEASE;
    const isCash = offerType === CASH;

    const setStatesByCategoryLookup = useMemo(
        () => ({
            Government: setGovFees,
            Dealer: setDealerFees,
            Lender: setLenderFees
        }),
        [setGovFees, setDealerFees, setLenderFees]
    );

    const handleDelete: FeesFormContextProps['handleDelete'] = useCallback(
        ({ category, code, isDelete, index }) => {
            const setStateFunc = setStatesByCategoryLookup[category];

            if (!setStateFunc) return;

            if (!code) {
                setStateFunc((fees) => fees.filter((fee, ind) => fee.feeTypeId !== code && ind !== index));
                return;
            }

            setStateFunc((fees) =>
                fees.map((fee, ind) =>
                    fee.feeTypeId === code && ind === index
                        ? {
                              ...fee,
                              isDeleted: isDelete
                          }
                        : fee
                )
            );
        },
        [setStatesByCategoryLookup]
    );

    const handleInputChange: FeesFormContextProps['handleInputChange'] = useCallback(
        ({ category, field, index, value }) => {
            const setStateFunc = setStatesByCategoryLookup[category];

            if (!setStateFunc) return;

            setStateFunc((prev) => {
                const updatedFees = [...prev];
                const feeToUpdate = updatedFees[index];

                if (feeToUpdate[field] !== value) {
                    updatedFees[index] = { ...feeToUpdate, [field]: value, isEdited: true };
                    return updatedFees;
                }

                // istanbul ignore next
                return prev;
            });
        },
        [setStatesByCategoryLookup]
    );

    const handleAddFee: FeesFormContextProps['handleAddFee'] = useCallback(
        ({ category, feeItems }) => {
            const setStateFunc = setStatesByCategoryLookup[category];
            setStateFunc([...feeItems, createNewFee(isLease, category)]);
        },
        [isLease, setStatesByCategoryLookup]
    );

    const handleSaveClick = () => {
        const updatedFees = [...govFees, ...dealerFees, ...lenderFees];
        const filteredUpdatedFees = updatedFees.filter((fees) => fees.isEdited || fees.isDeleted);
        const uniqueFilteredUpdatedFees = mergeFeeUpdates(filteredUpdatedFees);

        const feesOverrides = uniqueFilteredUpdatedFees.map<paymentServicesTypes.FeeOverride>((fee) => ({
            category: fee.category,
            feeAmount: fee.isDeleted ? ELIMINATED_FEE_AMOUNT : Number(fee.feeAmount),
            feeDescription: fee.feeDescription,
            feeName: fee.feeName,
            feeTypeId: Number(fee.feeTypeId),
            includeInCalcIndicator: fee.includedInCalc,
            reqCapIndicator: fee.capIndicator
        }));

        onSave?.(feesOverrides);
        toggleShowEditAction();
    };

    const handleConfirmDialogHide = () => {
        setShowConfirmDialog(false);
    };

    const handleConfirmDialogDone = () => {
        setShowConfirmDialog(false);

        onReset?.();
        toggleShowEditAction();
    };

    const handleConfigDialogCancel = () => {
        setShowConfirmDialog(false);
    };

    const handleResetClick = () => {
        setShowConfirmDialog(true);
    };

    const getIsIncludeInCalc: FeesFormContextProps['getIsIncludeInCalc'] = useCallback(
        ({ category, includedInCalc }) => {
            return !includedInCalc && category === FeesCategoriesType.LENDER && isLease;
        },
        [isLease]
    );

    const getCurrentlySelectedFees = useCallback(() => {
        return [...lenderFees, ...govFees, ...dealerFees];
    }, [lenderFees, govFees, dealerFees]);

    const providerValue: FeesFormContextProps = useMemo(
        () => ({
            handleInputChange,
            handleDelete,
            getIsIncludeInCalc,
            handleAddFee,
            isLease,
            setDisableFeeSubmit,
            getCurrentlySelectedFees
        }),
        [getIsIncludeInCalc, handleAddFee, handleDelete, handleInputChange, isLease, getCurrentlySelectedFees]
    );

    return (
        <Box>
            <FeesFormContext.Provider value={providerValue}>
                {!isCash && enableLenderFeeEdits && (
                    <FeesFormCategoryItems
                        categoryTitle="Lender Fees"
                        feeItems={lenderFees}
                        categoryType="Lender"
                        dataTestId="form_category_lender_fees"
                    />
                )}
                <FeesFormCategoryItems
                    categoryTitle="Government Fees"
                    feeItems={govFees}
                    categoryType="Government"
                    dataTestId="form_category_gov_fees"
                />
                <FeesFormCategoryItems
                    categoryType="Dealer"
                    categoryTitle="Dealer Fees"
                    feeItems={dealerFees}
                    dataTestId="form_category_dealer_fees"
                />
            </FeesFormContext.Provider>

            <FlexFeesButtonContainer id="deal-fees-button-container">
                <Button data-testid="btn-deal-fees-edit-cancel" buttonStyle="tertiary" type="submit" onClick={toggleShowEditAction}>
                    Cancel
                </Button>
                <Button data-testid="btn-deal-fees-edit-reset" buttonStyle="secondary" type="reset" onClick={handleResetClick}>
                    Reset All
                </Button>
                <Button
                    data-testid="btn-deal-fees-edit-save"
                    buttonStyle="primary"
                    type="submit"
                    disabled={disableFeeSubmit || !hasChangedFees || !areFeesValid}
                    onClick={handleSaveClick}
                >
                    Save
                </Button>
                {showConfirmDialog && (
                    <FeeResetDialog
                        data-testid="reset-fees-confirm-dialog"
                        onHide={handleConfirmDialogHide}
                        onConfirm={handleConfirmDialogDone}
                        onCancel={handleConfigDialogCancel}
                    />
                )}
            </FlexFeesButtonContainer>
        </Box>
    );
};

export default FeesForm;
