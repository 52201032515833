import { paymentServicesTypes } from '@makemydeal/dr-platform-types';

import { Box } from '@interstate/components/Box';
import { Typography } from '@interstate/components/Typography';

import TaxItemSummaryDetailed from './TaxItemSummaryDetailed';
import TaxItemSummarySimple from './TaxItemSummarySimple';

import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { hasTaxBreakdown } from './helpers';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import { actionCreators } from '@makemydeal/dr-offer-redux';
import { useDispatch, useSelector } from 'react-redux';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { LoadingSectionParent, PaymentLoading } from '@makemydeal/dr-dash-components';
import { PriceInput } from '@interstate/components/PriceInput';
import React from 'react';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { MUT_TAX, PRIMARY_CLASS } from '../constants';

const InputLabelStyle = {
    '.ids-input-component-label': {
        justifyContent: 'end !important'
    }
};

const MAX_VAL = 999999;

const TaxItemSummary: React.FC<{ taxItem: paymentServicesTypes.TaxItem }> = ({ taxItem }) => {
    const dispatch = useDispatch();
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const isUseOverrideTaxAmountForPayment = useSelector(featureToggleSelectors.useOverrideTaxAmountForPayment);

    const handleChange = (event: InterstateOnChangeEvent<TextInputEventValue>, keyName: string) => {
        const newRate = parseFloat(event.target.value);
        const taxBreakdown = taxItem.taxBreakdown as Record<string, any>;
        /* istanbul ignore next */
        if (taxBreakdown?.[keyName] === newRate || (!taxBreakdown && taxItem.taxRate === newRate)) {
            return;
        }
        const updatedTaxItem = {
            ...taxItem,
            taxBreakdown: {
                ...taxItem.taxBreakdown,
                [keyName]: newRate
            },
            manualChanges: {
                ...taxItem.manualChanges,
                [keyName]: true
            },
            isManualTax: true
        };
        const action = actionCreators.updateTaxItems([updatedTaxItem], offerType, true);
        dispatch(action);
    };

    return (
        <LoadingSectionParent>
            <PaymentLoading testId="tax-items-loading-indicator" />
            <Box data-testid={`individualTax-${taxItem.taxName}`} sx={{ paddingLeft: 2, paddingRight: 2 }}>
                <Typography variant="body-md" color="base.color.black">
                    {taxItem.taxName}
                </Typography>
                <Box>
                    {hasTaxBreakdown(taxItem) ? (
                        <TaxItemSummaryDetailed taxItem={taxItem} key={taxItem.taxName} handleChange={handleChange} />
                    ) : (
                        <TaxItemSummarySimple taxItem={taxItem} handleChange={handleChange} />
                    )}
                </Box>
                {isUseOverrideTaxAmountForPayment && taxItem.taxName === MUT_TAX ? (
                    <Box display="flex" justifyContent="flex-end" marginTop="1rem">
                        <Box maxWidth="23%" display="inline-flex" gap="2rem" paddingRight="1.5rem">
                            <PriceInput
                                sx={InputLabelStyle}
                                autoInsertCommas
                                allowNegative={false}
                                id={'monthly-tax-item-input'}
                                data-testid={'monthly-tax-item-input'}
                                maxValue={MAX_VAL}
                                allowDecimal={true}
                                textAlign="right"
                                decimalMaxLength={2}
                                label="Monthly Tax"
                                name={'monthly-tax-item-summary'}
                                value={formatDollarsAndCents(taxItem.monthlyTax)}
                            />
                            <Box display="grid" textAlign="end">
                                <Typography variant="label-md" color="sem.color.on-surface.muted">
                                    Tax Total
                                </Typography>
                                <Typography variant="body-md" tag="span">
                                    {formatDollarsAndCents(taxItem.taxAmount)}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ) : isUseOverrideTaxAmountForPayment && taxItem.class === PRIMARY_CLASS && taxItem.taxName !== MUT_TAX ? (
                    <Box display="flex" justifyContent="flex-end" marginTop="1rem">
                        <Box maxWidth="13%" paddingRight="1.5rem">
                            <PriceInput
                                sx={InputLabelStyle}
                                autoInsertCommas
                                allowNegative={false}
                                id={'tax-item-summary-input'}
                                data-testid={'tax-item-summary-input'}
                                maxValue={MAX_VAL}
                                allowDecimal={true}
                                textAlign="right"
                                decimalMaxLength={2}
                                label="Tax Total"
                                name={'tax-item-summary'}
                                value={formatDollarsAndCents(taxItem.taxAmount)}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Box display="flex" justifyContent="flex-end" marginTop={1.5} paddingRight={1.8}>
                        <span>{`Total: ${formatDollarsAndCents(taxItem.taxAmount)}`}</span>
                    </Box>
                )}
            </Box>
        </LoadingSectionParent>
    );
};

export default TaxItemSummary;
