// types
import { DealHistory } from '@makemydeal/dr-dash-types';
import { FC } from 'react';

// selectors
import { offerReduxSelectors, offerSelectors, tradeInSelectors } from '@makemydeal/dr-dash-store';
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';

// components
import DealSummaryItem from '../common/DealSummaryItem';
import LineItemWithChildren from '../common/LineItemWithChildren';

// constants
import * as constants from '../../constants';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// styles
import { labelStyles, valueStyles } from '../utils/styles';

export type AdjustedResidualProps = {
    deal?: DealHistory;
};

const DueAtSigningPaidBy: FC<AdjustedResidualProps> = ({ deal }) => {
    const useDealRootSelector = createDealRootSelector(deal);
    const netTradeValue = useDealRootSelector(tradeInSelectors.getTradeNetValue);
    const totalRebates = useDealRootSelector(offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash);
    const outOfPocket = useDealRootSelector(offerSelectors.getLeaseOutOfPocket);
    const priorLoanBalance = useDealRootSelector(offerReduxSelectors.getPriorLoanBalance);

    const dueAtSigningAmount = netTradeValue + totalRebates + outOfPocket + priorLoanBalance;

    return (
        <DealSummaryItem
            labelStyles={labelStyles}
            valueStyles={valueStyles}
            label={constants.DUE_AT_SIGNING_PAID_BY}
            value={formatDollarsAndCents(dueAtSigningAmount)}
        >
            <LineItemWithChildren label={constants.NET_TRADE_IN_ALLOWANCE} value={formatDollarsAndCents(netTradeValue)} />
            <LineItemWithChildren label={constants.REBATES_AND_NONCASH_CREDITS} value={formatDollarsAndCents(totalRebates)} />
            <LineItemWithChildren label={constants.AMOUNT_PAID_BY_CUSTOMER} value={formatDollarsAndCents(outOfPocket)} />
            <LineItemWithChildren label={constants.ADDITIONAL_CAPITALIZED_COST} value={formatDollarsAndCents(priorLoanBalance)} />
            <LineItemWithChildren label={constants.AMOUNT_PAID_BY_DEALER} value={formatDollarsAndCents(0)} />
        </DealSummaryItem>
    );
};

export default DueAtSigningPaidBy;
