// selectors
import {
    accessoriesSelectors,
    compositeSelectors,
    offerReduxSelectors,
    offerSelectors,
    vehicleProtectionSelectors
} from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';

// types
import { DealHistory } from '@makemydeal/dr-dash-types';
import { IDealerFee as DealerFee } from '@makemydeal/dr-platform-shared';

// consts/enums
import * as constants from '../../constants';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { vehicleProtectionUtils } from '@makemydeal/dr-dash-store';
import {
    convertAccessoryToLineItem,
    convertDealerFeeToLineItem,
    convertGovernmentFeeToLineItem,
    convertLenderFeeToLineItem,
    convertVppProductToLineItem
} from '../utils/transform';

// components
import { Grid } from '@interstate/components/Grid';
import DealSummaryItem from '../common/DealSummaryItem';
import LineItemWithChildren from '../common/LineItemWithChildren';

// hooks
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { labelStyles, secondaryValueStyles, valueStyles } from '../utils/styles';

const expandibleAreaStyles = {
    pr: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
};

const expandibleButtonStyles = {
    py: 1,
    px: 0
};

export type AddOnsProps = {
    deal?: DealHistory;
    hasDelta?: boolean;
};

const Addons = ({ deal, hasDelta = false }: AddOnsProps) => {
    const useDealRootSelector = createDealRootSelector(deal);
    const isEnhancedDealDetailsEnabled = useSelector(featureToggleSelectors.getEnhancedDealDetails);

    // total add-ons
    const addons = useDealRootSelector(compositeSelectors.getTotalAddons);

    // accessories
    const accessoriesTotal = useDealRootSelector(accessoriesSelectors.getAccessoriesTotalAmount);
    const itemizedAccessories = useDealRootSelector(accessoriesSelectors.getAccessoriesList).map(convertAccessoryToLineItem);

    // protection products
    const protectionProductsTotal = useDealRootSelector(
        (state) => vehicleProtectionSelectors.getVppSummary(state).totalProductPrice
    );
    const vppProducts = useDealRootSelector(vehicleProtectionSelectors.getVppProducts);
    const protectionProductsItems = useMemo(
        () => vppProducts.filter(vehicleProtectionUtils.isVppProductSelected).map(convertVppProductToLineItem),
        [vppProducts]
    );

    // dealer fees
    const dealerFeesItems: DealerFee[] = useDealRootSelector(offerSelectors.getDealerFees);
    const dealerFeesTotal = useDealRootSelector(offerSelectors.getDealerFeeTotal);
    const dealerFeeItems = useMemo(
        () => dealerFeesItems.filter((fee) => fee.dealerFeeAmount > 0).map(convertDealerFeeToLineItem),
        [dealerFeesItems]
    );

    // government fees
    const governmentFeeTotal = useDealRootSelector(offerSelectors.getDmvFeeTotal);
    const dmvFeeList = useDealRootSelector(offerSelectors.getDmvFeeList);
    const govFeeItems = useMemo(() => dmvFeeList.filter((fee) => fee.amount > 0).map(convertGovernmentFeeToLineItem), [dmvFeeList]);

    // lender fees
    const lenderFeesTotal = useDealRootSelector(compositeSelectors.getLenderFeesTotal);
    const itemizedLenderFees = useDealRootSelector(offerReduxSelectors.getItemizedLenderFees);
    const lenderFeeItems = useMemo(
        () => itemizedLenderFees.filter((fee) => fee.dealerFeeAmount > 0).map(convertLenderFeeToLineItem),
        [itemizedLenderFees]
    );

    const headerStyles = isEnhancedDealDetailsEnabled ? { valueStyles, labelStyles } : {};

    return (
        <DealSummaryItem
            {...headerStyles}
            label={constants.TOTAL_ADD_ONS}
            value={formatDollarsAndCents(addons)}
            hasDelta={hasDelta}
        >
            <Grid container flexDirection="column">
                {isEnhancedDealDetailsEnabled ? (
                    <>
                        <DealSummaryItem
                            valueStyles={secondaryValueStyles}
                            labelStyles={labelStyles}
                            expandibleButtonStyles={expandibleButtonStyles}
                            expandibleAreaStyles={expandibleAreaStyles}
                            label={constants.DEALER_FEES}
                            value={formatDollarsAndCents(dealerFeesTotal)}
                        >
                            {!!dealerFeeItems.length &&
                                dealerFeeItems.map((fee) => (
                                    <LineItemWithChildren key={fee.label} label={fee.label} value={fee.value} />
                                ))}
                        </DealSummaryItem>
                        <DealSummaryItem
                            valueStyles={secondaryValueStyles}
                            labelStyles={labelStyles}
                            expandibleButtonStyles={expandibleButtonStyles}
                            expandibleAreaStyles={expandibleAreaStyles}
                            label={constants.GOV_FEES}
                            value={formatDollarsAndCents(governmentFeeTotal)}
                        >
                            {!!govFeeItems.length &&
                                govFeeItems.map((fee) => (
                                    <LineItemWithChildren key={fee.label} label={fee.label} value={fee.value} />
                                ))}
                        </DealSummaryItem>
                        <DealSummaryItem
                            valueStyles={secondaryValueStyles}
                            labelStyles={labelStyles}
                            expandibleButtonStyles={expandibleButtonStyles}
                            expandibleAreaStyles={expandibleAreaStyles}
                            label={constants.LENDER_FEES}
                            value={formatDollarsAndCents(lenderFeesTotal)}
                        >
                            {!!lenderFeeItems.length &&
                                lenderFeeItems.map((fee) => (
                                    <LineItemWithChildren key={fee.label} label={fee.label} value={fee.value} />
                                ))}
                        </DealSummaryItem>

                        <DealSummaryItem
                            valueStyles={secondaryValueStyles}
                            labelStyles={labelStyles}
                            expandibleButtonStyles={expandibleButtonStyles}
                            expandibleAreaStyles={expandibleAreaStyles}
                            label={constants.PROTECTION_PRODUCTS}
                            value={formatDollarsAndCents(protectionProductsTotal)}
                        >
                            {!!protectionProductsItems.length &&
                                protectionProductsItems.map((product) => (
                                    <LineItemWithChildren key={product.label} label={product.label} value={product.value} />
                                ))}
                        </DealSummaryItem>

                        <DealSummaryItem
                            valueStyles={secondaryValueStyles}
                            labelStyles={labelStyles}
                            expandibleButtonStyles={expandibleButtonStyles}
                            expandibleAreaStyles={expandibleAreaStyles}
                            label={constants.ACCESSORIES}
                            value={formatDollarsAndCents(accessoriesTotal)}
                        >
                            {!!itemizedAccessories.length &&
                                itemizedAccessories.map((accessory) => (
                                    <LineItemWithChildren key={accessory.label} label={accessory.label} value={accessory.value} />
                                ))}
                        </DealSummaryItem>
                    </>
                ) : (
                    <>
                        <LineItemWithChildren
                            label={constants.ACCESSORIES}
                            value={formatDollarsAndCents(accessoriesTotal)}
                            childItems={itemizedAccessories}
                        />
                        <LineItemWithChildren
                            label={constants.PROTECTION_PRODUCTS}
                            value={formatDollarsAndCents(protectionProductsTotal)}
                            childItems={protectionProductsItems}
                        />
                        <LineItemWithChildren
                            label={constants.FEES}
                            value={formatDollarsAndCents(dealerFeesTotal)}
                            childItems={dealerFeeItems}
                        />
                        <LineItemWithChildren
                            label={constants.GOV_FEES}
                            value={formatDollarsAndCents(governmentFeeTotal)}
                            childItems={govFeeItems}
                        />
                    </>
                )}
            </Grid>
        </DealSummaryItem>
    );
};

export default Addons;
